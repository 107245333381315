<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>分类：</span>
            <el-select v-model="pageInfo.questionTypeId"
                       class="m-2"
                       placeholder="选择分类"
                       size="large">
              <el-option
                  v-for="item in classificationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div class="marginLeft15">
            <span>题干：</span>
            <input v-model="pageInfo.title" placeholder="题干" class="searchInput" />
          </div>
          <div class="marginLeft15">
            <span>分值：</span>
            <input v-model="pageInfo.score"
                   placeholder="分值"
                   class="searchInput"
                   @blur="floatParse(pageInfo.score, val => pageInfo.score = val)" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">题目列表</div>
          <div class="flex">
            <!-- 导入题目 -->
            <div>
              <el-upload class="upload-demo"
                         :action="'/'+$root.projectName + '/question/import?Authorization=Bearer '+token"
                         list-type="picture"
                         accept=".xls,.xlsx"
                         :show-file-list="false"
                         :on-progress="beforxfg"
                         :on-success="fileVb">
                <button class="exportBtn">导入题目</button>
              </el-upload>
            </div>
            <!-- 下载导入模板 -->
            <button class="exportBtn marginLR10" @click="showDown('question')">下载模板</button>
            <!-- 录入题目 -->
            <button class="addBtn marginLR10" @click="showEdit(null)"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="title" label="题目" />
          <el-table-column prop="score" label="分值" />
          <el-table-column prop="type" label="题目类型">
            <template #default="{row}">
              <span>{{ row.type === 0 ? '单选' : '其他'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="录入时间" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {downFile} from "@/api/api";
import {getAllQuestionType, getQuestionPage} from "@/api/examination";
import {floatParse} from "@/utils/common";
export default {
  name: "questionList",
  data(){
    return{
      token:localStorage.getItem('token'),
      tableData:[],
      dataInfo:[],
      Info:null,
      dialogFormVisible:false,
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        questionTypeId:'',
        title:'',
        score: '',
      },
      exportIds:'',
      // ------------------------------------
      classificationOptions: [],
    }
  },
  mounted() {
    this.getList();
    this.getOption()
  },
  methods:{
    floatParse,
    /**
     * 获取分类
     */
    getOption(){
      getAllQuestionType().then(res => {
        res.data.forEach(iter => {
          this.classificationOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const questionTypeId = ''
      const title = ''
      const score =  ''
      this.pageInfo = { ...this.pageInfo, questionTypeId, title, score }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getQuestionPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    showEdit(row){
      // this.dialogFormVisible=true;
      // this.Info=row;
      // let data='';
      // if(row) {
      //   data = JSON.stringify(this.Info);
      // }
      // this.$root.useRouter.push({path:"/asset/assetEdit",query:{info:data}});
      if (row) {
        this.$root.useRouter.push({ path:`/question/add/${row.id}` });
        return
      }
      this.$root.useRouter.push({ path:"/question/add" })
    },
    showRenew(id){
      this.dialogFormVisible1=true;
      this.$refs.renewDiv.getInfo(id);//调用子组件方法
    },
    closeDiv(){
      this.dialogFormVisible=false;
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
    showDown(name){
      downFile(name);
    },
    beforxfg(){
      this.loading = this.$root.loadingOpen();
    },
    fileVb(res){
      if(res.success){
        this.$root.ElMessage({type:'success',message:res.message});
        this.getList();
      }else{
        this.$root.ElMessage.error(res.message);
      }
      this.loading.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>